<template>
    <section class="dashboard container-fluid bg-white p-4">
        <div class="row">
            <div class="col-12 d-flex justify-content-between mb-4">
                <span class="ge--description-md">
                    <b>Pacientes</b>
                </span>
                <button class="btn btn-primary btn-pill" @click="show_modal_patient = true">
                    <span class="opensans-semibold">
                        Agregar Paciente
                    </span>
                </button>
            </div>

            <div class="col-12 mb-4">
                <ge-table :data="data_table" :allow_headers="allow_headers" 
                    :items_per_page="items_per_page" :current_page="current_page" :total_pages="total_pages" pagination
                    @previous_page="current_page = current_page - 1, listPatient()"
                    @next_page="current_page = current_page + 1, listPatient()"
                    @change_items_per_page="changeItemsPerPage($event)">

                    <template v-slot:studio_blood="data">
                        <div v-if="data.row.status_studio_blood == 'loaded'" class="flex-center">
                            <div class="tag tag-success">
                                <span class="ge--description-xs text-success">
                                    Cargado
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <button @click="openChargeModal(data.row, 'blood')"
                                class="btn btn-outline-light btn-pill">
                                    <span class="ge--description-xs">
                                        <img :src="ico_upload" >
                                        Cargar PDF
                                    </span>
                            </button>
                        </div>
                    </template>

                    <template v-slot:studio_genetic="data">
                        <div v-if="data.row.status_studio_genetic == 'loaded'" class="flex-center">
                            <div class="tag tag-success">
                                <span class="ge--description-xs text-success">
                                    Cargado
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <button @click="openChargeModal(data.row, 'genetic')"
                                class="btn btn-outline-light btn-pill">
                                    <span class="ge--description-xs">
                                        <img :src="ico_upload">
                                        Cargar PDF
                                    </span>
                            </button>
                        </div>
                    </template>

                    <template v-slot:birthday="data">
                        <span class="ge--description-xs text-dark">
                            {{ $informativeDate(data.value) }}
                        </span>
                    </template>

                    <template v-slot:gender="data">
                        <span class="ge--description-xs text-dark">
                            {{ (data.value == 'male') ? 'Masculino' : 'Femenino' }}
                        </span>
                    </template>

                    <template v-slot:genomics="data">
                        <div v-if="data.row.order_id !== null &&
                            data.row.status_studio_blood == 'loaded' && 
                            data.row.status_studio_genetic == 'loaded'" 
                            class="flex-center">

                            <!-- <div class="tag tag-success">
                                <span class="ge--description-xs text-success">
                                    Disponible
                                </span>
                            </div> -->
                            <button @click="genereteReport360(data.row.id, data.row.order_id)"
                                class="btn btn-pill btn-sm btn-outline-light">
                                <span class="ge--description-xs text-dark">
                                    Generar reporte 360
                                </span>
                            </button>
                        </div>
                        <div v-else class="flex-center">
                            <div class="tag tag-warning">
                                <span class="ge--description-xs text-warning">
                                    Pendiente
                                </span>
                            </div>
                        </div>
                    </template>

                    <template v-slot:see="data">
                        <section class="">   
                            <button @click="viewPatient(data.row.id, data.row)"
                                class="btn btn-outline-light btn-pill my-1">

                                <span class="ge--description-xs text-dark">
                                    <img :src="icon_eye" style="width: 14px; height: 14px;"> 
                                    Ver Paciente
                                </span>
                            </button>
                        </section>
                    </template>

                    <template v-slot:delete="data">
                        <section class="">
                            <button @click="showModalConfirmation('delete_patient', data.row)"
                                class="btn btn-outline-light btn-pill my-1">

                                <span class="ge--description-xs text-dark">
                                    <img :src="ico_delete" style="width: 24px; height: 24px;"> 
                                    Eliminar Paciente
                                </span>
                            </button>
                        </section>
                    </template>

                </ge-table>
            </div>

            <ge-modal :show_modal="show_modal_patient" 
                @close="handleClose()"
                :overlay_close="false"
                max_width="1300"
                variant="white">

                <div class="container" style="max-height: 80vh; height: 100%; overflow-y: scroll;">

                    <div class="row flex-center m-0">
                        <div class="col-12 flex-center py-5" v-if="movement_type == 'add'">
                            <span class="ge--description-md text-primary opensans-black">
                                Agregar Paciente
                            </span>
                        </div>
                        <div class="col-12 col-sm-3 pt-5 pb-1 py-sm-5" v-if="movement_type == 'edit'">
                            <button @click="show_section_modal = 1"
                                :class="`btn w-100 ${(show_section_modal == 1) ? 
                                    'btn-primary text-white' : 
                                    'btn-outline-primary' }`">
                                <span class="ge--description-xs opensans-bold">
                                    Editar Paciente
                                </span>
                            </button>
                        </div>
                        <div class="col-12 col-sm-3 py-1 py-sm-5" v-if="movement_type == 'edit'">
                            <button @click="show_section_modal = 2"
                                :class="`btn w-100 ${(show_section_modal == 2) ? 
                                    'btn-primary text-white' : 
                                    'btn-outline-primary' }`">
                                <span class="ge--description-xs opensans-bold">
                                    Órdenes
                                </span>
                            </button>
                        </div>
                        <div class="col-12 col-sm-3 pt-1 pb-5 py-sm-5" v-if="movement_type == 'edit'">
                            <button @click="show_section_modal = 3"
                                :class="`btn w-100 ${(show_section_modal == 3) ? 
                                    'btn-primary text-white' : 
                                    'btn-outline-primary' }`">
                                <span class="ge--description-xs opensans-bold">
                                    Estudios
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="row flex-center  px-3 px-md-5 pb-3 pb-md-5 m-0" 
                        v-show="movement_type == 'add' || show_section_modal == 1">

                        <div class="col-12 col-sm-9 col-lg-5 flex-center mb-4">
                            <ge-input v-model="first_name" label="Nombre"/>
                        </div>

                        <div class="col-12 col-sm-9 col-lg-5 flex-center mb-4">
                            <ge-input v-model="last_name" label="Apellidos"/>
                        </div>

                        <div class="col-12 col-sm-9 col-lg-5 text-center mb-4">
                            <span class="opensans-bold">
                                <small>Género</small>
                            </span>
                            <select v-model="gender" name="select" class="select-control form-control mb-3">
                                <option disabled hidden selected>
                                    Género
                                </option>
                                <option value="male">
                                    Masculino
                                </option>
                                <option value="female">
                                    Femenino
                                </option>
                            </select>
                        </div>

                        <div class="col-12 col-sm-9 col-lg-5 flex-center mb-4">
                            <ge-input v-model="birthday" label="Fecha de Nacimiento" type="date"/>
                        </div>

                        <div class="col-12 col-sm-9 col-lg-5 flex-center mb-4">
                            <ge-input v-model="email" label="Correo"/>
                        </div>

                        <div class="col-12 col-sm-9 col-lg-5 flex-center mb-4">
                            <ge-input v-model="phone" label="Teléfono" :max_length="10"/>
                        </div>

                        <div class="col-12 col-md-9 col-lg-9 flex-center">
                            <button @click="selectAction()" class="btn btn-primary btn-pill px-3">
                                <span class="opensans-semibold text-white">
                                    {{ movement_type == 'add' ? 'Agregar' : movement_type == 'edit' ? 'Editar' : '' }}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="row flex-center  px-3 px-md-5 pb-3 pb-md-5 m-0" 
                        v-show="movement_type == 'edit' && show_section_modal == 2">

                        <div class="col-12 flex-center">
                            <ge-table :data="data_table_orders" :allow_headers="allow_headers_orders">

                                <template v-slot:studio_blood_creation="data">
                                    <div @click="downloadPDF(data.row, 'blood')"
                                        :class="`tag tag-${(data.value !== null) ? 'success cursor-pointer' : 'warning'}`">
                                            <span :class="`ge--description-xs 
                                                text-${(data.value !== null) ? 'success' : 'warning'}`">
                                                    <img :src="ico_download" v-if="data.value !== null">
                                                    {{ (data.value !== null) ? 'Descargar' : 'Pendiente' }}
                                            </span>
                                    </div>
                                </template>

                                <template v-slot:order_id_genetic_file="data">
                                    <div @click="downloadPDF(data.row, 'genetic')"
                                        :class="`tag tag-${(data.value !== null) ? 'success cursor-pointer' : 'warning'}`">
                                            <span :class="`ge--description-xs 
                                                text-${(data.value !== null) ? 'success' : 'warning'}`">
                                                    <img :src="ico_download" v-if="data.value !== null">
                                                    {{ (data.value !== null) ? 'Descargar' : 'Pendiente' }}
                                            </span>
                                    </div>
                                </template>

                                <template v-slot:creation="data">
                                    <span class="ge--description-xs">
                                        {{ $informativeDate(data.value) }}
                                    </span>
                                </template>

                                <template v-slot:estatus="data">
                                    <div v-if="data.row.studio_blood_creation !== null && 
                                        data.row.order_id_genetic_file !== null" 
                                        class="flex-center">

                                        <div class="tag tag-success">
                                            <span class="ge--description-xs text-success">
                                                Finalizado
                                            </span>
                                        </div>
                                    </div>
                                    <div v-else class="flex-center">
                                        <div class="tag tag-warning">
                                            <span class="ge--description-xs text-warning">
                                                Analizando
                                            </span>
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:download="data">
                                    <button @click="downloadReport360(data.row.patient_id, data.row.order_id)"
                                        class="btn btn-outline-light btn-pill"
                                        :disabled="data.row.studio_blood_creation === null || 
                                        data.row.order_id_genetic_file === null">

                                        <span class="ge--description-xs text-dark">
                                            <img :src="ico_download">
                                            Descargar    
                                        </span>
                                    </button>
                                </template>

                                <template v-slot:delete="data">
                                    <button @click="showModalConfirmation('delete_blood', data.row)"
                                        class="btn btn-outline-light btn-pill">

                                        <span class="ge--description-xs text-dark">
                                            <img :src="ico_delete">
                                            Eliminar
                                        </span>
                                    </button>
                                </template>

                            </ge-table>
                        </div>
                    </div>

                    <div class="row flex-center-start px-3 px-md-5 m-0" 
                        v-show="movement_type == 'edit' && show_section_modal == 3"
                        style="overflow-y: scroll;">

                        <div class="col-12 col-sm-6 col-md-3 text-center">
                            <div>
                                <div style="height: 60px;">
                                    <span class="ge--description-md opensans-black text-primary">
                                        ESTUDIO EPIGENÉTICO
                                    </span>
                                </div>
                                <ge-drop-file _id="drop_epigenetic" 
                                    @charged="chargeExtraReport($event, 'epigenetic')">
                                    
                                </ge-drop-file>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 text-center">
                            <div>
                                <div style="height: 60px;">
                                    <span class="ge--description-md opensans-black text-primary">
                                        ESTUDIO COMPLETE GENOME
                                    </span>
                                </div>
                                <ge-drop-file _id="drop_genetic" 
                                    @charged="chargeGenomicReport($event)"
                                    :uploaded="uploaded_genetic"
                                    @download="downloadGenetic()">
                                    
                                </ge-drop-file>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 text-center">
                            <div>
                                <div style="height: 60px;">
                                    <span class="ge--description-md opensans-black text-primary">
                                        ESTUDIO <br> APO E
                                    </span>
                                </div>
                                <ge-drop-file _id="drop_apoe"
                                    @charged="chargeExtraReport($event, 'apoe')"
                                    :uploaded="uploaded_apoe"
                                    @download="downloadExtraReport($event)">
                                    
                                </ge-drop-file>
                            </div>
                            <button @click="showModalConfirmation('delete_apoe', null)"
                                class="btn btn-primary mt-2"
                                v-if="uploaded_apoe">
                                    <!-- @click="deleteExtraReport('apoe')"  -->
                                    <span class="ge--description-xs text-white">
                                        Eliminar
                                    </span>
                            </button>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 text-center">
                            <div>
                                <div style="height: 60px;">
                                    <span class="ge--description-md opensans-black text-primary">
                                        ESTUDIO FARMACOGENÉTICO
                                    </span>
                                </div>
                                <ge-drop-file _id="drop_pgx"
                                    @charged="chargeExtraReport($event, 'pgx')"
                                    :uploaded="uploaded_pgx"
                                    @download="downloadExtraReport($event)">
                                    
                                </ge-drop-file>
                            </div>
                            <button @click="showModalConfirmation('delete_pgx', null)" 
                                class="btn btn-primary mt-2"
                                v-if="uploaded_pgx">
                                    <!-- @click="deleteExtraReport('pgx')" -->
                                    <span class="ge--description-xs text-white">
                                        Eliminar
                                    </span>
                            </button>
                        </div>

                    </div>

                    <div class="row px-3 pb-3 pb-md-5" v-show="movement_type == 'edit' && show_section_modal == 3">
                        <div class="col-12 pt-4">
                            <ge-table :data="data_table_reports" :allow_headers="allow_headers_reports">

                                <template v-slot:type="data">
                                    <span class="ge--description-xs text-dark">
                                        {{ 
                                            (data.value == 'apoe') ? 'APO E' : 
                                            (data.value == 'pgx') ? 'FARMACOGENÉTICO' : 
                                            'EPIGENÉTICO'
                                        }}
                                    </span>
                                </template>

                                
                                <template v-slot:date_sample_taking="data">
                                    <div v-if="data.value !== null">
                                        <span class="ge--description-xs text-dark">
                                            {{ $informativeDate(data.value) }}
                                        </span>
                                    </div>
                                    <div v-else>
                                        <button @click="show_modal_date = true, study_date = data.row.creation, smd_id_report = data.row.id" 
                                            class="btn btn-outline-light btn-pill">
                                                <img :src="ico_calendar" class="float-left mr-5">
                                                <span class="ge--description-xs align-middle">
                                                    Asignar fecha
                                                </span>
                                        </button>
                                    </div>
                                </template>

                                <template v-slot:creation="data">
                                    <span class="ge--description-xs text-dark">
                                        {{ $informativeDate(data.value) }}
                                    </span>
                                </template>

                                <template v-slot:download="data">
                                    <button @click="downloadExtraReport('epigenetic', data.row)"
                                        class="btn btn-outline-light btn-pill">
                                        <span class="ge--description-xs text-dark">
                                            <img :src="ico_download">
                                            Descargar    
                                        </span>
                                    </button>
                                </template>

                                <template v-slot:delete="data">
                                    <button @click="showModalConfirmation('delete_epigenetic', data.row)"
                                        class="btn btn-outline-light btn-pill">

                                        <span class="ge--description-xs text-dark">
                                            <img :src="ico_delete">
                                            Eliminar
                                        </span>
                                    </button>
                                </template>

                            </ge-table>
                        </div>
                    </div>
                </div>

            </ge-modal>

            <ge-modal :show_modal="show_modal_charge" 
                @close="show_modal_charge = false"
                :overlay_close="false"
                variant="white">

                    <div class="container">
                        <div class="row flex-center h-100">
                            <div class="col-12 text-center py-3">
                                <span class="ge--description-sm text-primary opensans-black">
                                    Subir estudio 
                                    {{
                                        charge_type == 'blood' ? 'sanguíneo' :
                                        charge_type == 'genetic' ? 'genético'  : ''
                                    }}
                                </span>
                            </div>
                            <div class="col-12 text-center py-3 mb-3">
                                <label for="file-input" class="btn btn-primary">
                                    <span class="ge--description-xs text-white">
                                        Subir archivo
                                    </span>

                                    <input id='file-input' type="file" class="d-none" @change="chargePDF()">
                                </label>

                            </div>
                        </div>
                    </div>
                
            </ge-modal>

            <ge-modal :show_modal="show_modal_confirmation" 
                @close="handleCloseConfirmation()"
                :overlay_close="false"
                variant="white">

                    <div class="container">
                        <div class="row flex-center h-100">
                            <div class="col-12 text-center py-3">
                                <span class="ge--description-sm text-primary opensans-black"
                                    v-html="title_confirmation">
                                </span>
                            </div>
                            <div class="col-12 text-center py-3 mb-3">
                                <button @click="actionConfirmation()" class="btn btn-primary me-2">
                                    <span class="ge--description-xs text-white">
                                        Si
                                    </span>
                                </button>
                                <button @click="handleCloseConfirmation()" class="btn btn-primary ms-2">
                                    <span class="ge--description-xs text-white">
                                        Cancelar
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                
            </ge-modal>

            <ge-modal :show_modal="show_modal_date" 
                @close="handleCloseDateSample()"
                :overlay_close="false"
                variant="white">

                    <div class="container">
                        <div class="row flex-center h-100">
                            <div class="col-12 text-center py-3 mb-3">
                                <span class="ge--description-sm text-primary opensans-black">
                                    Selecciona una fecha de toma de muestra <br>
                                    para el reporte creado el día {{ $informativeDate(study_date) }}
                                </span>
                            </div>
                            <div class="col-12 px-5">
                                <ge-input v-model="date_sample_taking" label="Fecha de toma de muestra" type="date"/>
                            </div>
                            <div class="col-12 text-center py-3 mb-3">
                                <button @click="saveDateSample()" class="btn btn-primary me-2">
                                    <span class="ge--description-xs text-white">
                                        Guardar
                                    </span>
                                </button>
                                <button @click="handleCloseDateSample()" 
                                    class="btn btn-primary ms-2">
                                        <span class="ge--description-xs text-white">
                                            Cancelar
                                        </span>
                                </button>
                            </div>
                        </div>
                    </div>
                
            </ge-modal>

            
        </div>
    </section>
</template>

<script setup>
    import { ref, reactive, computed, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images
    const ico_download = ref(require('ASSETS/icons/download'))
    const ico_delete = ref(require('ASSETS/icons/delete'))
    const ico_upload = ref(require('ASSETS/icons/upload'))
    const icon_eye = ref(require('ASSETS/icons/eye'))
    const icon_plus = ref(require('ASSETS/icons/plus'))
    const ico_calendar = ref(require('ASSETS/icons/calendar'))

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects
    const $sendRequest = inject('$sendRequest')
    const $informativeDate = inject('$informativeDate')

    // Computeds

    // Variables
    let show_modal_patient = ref(false)
    const movement_type = ref('add')

    const allow_headers = ref([
        // { key: 'id', label: "ID" }, 
        { key: 'last_name', label: "Apellidos" }, 
        { key: 'first_name', label: "Nombre" }, 
        { key: 'gender', label: "Género" }, 
        { key: 'birthday', label: "Fecha de Nacimiento" }, 
        { key: 'age', label: "Edad" }, 
        { key: 'studio_genetic', label: "Estudio Genético" }, 
        { key: 'studio_blood', label: "Estudio Sanguíneo" }, 
        { key: 'genomics', label: "Reporte G3NOMICS 360" }, 
        { key: 'see', label: "Ver" },
        { key: 'delete', label: "Eliminar" }
    ])
    const allow_headers_orders = ref([
        { key: 'order_number', label: "Número de orden" }, 
        { key: 'studio_blood_creation', label: "Estudio Sanguíneo" }, 
        { key: 'order_id_genetic_file', label: "Estudio Genético" }, 
        { key: 'creation', label: "Fecha de la orden" }, 
        { key: 'estatus', label: "Estado actual" }, 
        { key: 'download', label: "Reporte G3NOMICS 360" },
        { key: 'delete', label: "Eliminar" }
    ])
    const allow_headers_reports = ref([
        { key: 'type', label: "Nombre" }, 
        { key: 'date_sample_taking', label: "Fecha de toma de muestra" }, 
        { key: 'creation', label: "Fecha de carga" }, 
        { key: 'download', label: "Descarga" },
        { key: 'delete', label: "Eliminar" },
    ])
    const data_table = ref(null)
    const data_table_orders = ref(null)
    const data_table_reports = ref(null)

    let items_per_page = ref(25)
    let current_page = ref(1)
    let total_pages = ref(1)


    let show_modal_charge = ref(false)
    const charge_type = ref('blood')
    let show_section_modal = ref(1)
    let row_current = reactive({})
    let current_info_patient = reactive({})
    let order_id = ref(null)

    let show_modal_confirmation = ref(false)
    let title_confirmation = ref(null)
    let action_confirmation = ref(null)
    let data_confirmation = ref(null)

    let show_modal_date = ref(null)
    let date_sample_taking = ref(null)
    let study_date = ref(null)
    let smd_id_report = ref(null)



    const local_id_patient = ref(null)
    const first_name = ref(null)
    const last_name = ref(null)
    const gender = ref(null)
    const birthday = ref(null)
    const email = ref(null)
    const phone = ref(null)
    let file_blood = reactive({})
    let file_genetic = reactive({})

    const uploaded_apoe = ref(false)
    const id_apoe = ref(0)
    const uploaded_pgx = ref(false)
    const id_pgx = ref(0)
    const uploaded_genetic = ref(false)
    let genetic_data = reactive({})
    

    // Methods
    const handleClose = () => {
        show_modal_patient.value = false
        show_section_modal.value = 1
        movement_type.value = 'add'
        current_info_patient = {}

        // Reset variables
        local_id_patient.value = null
        first_name.value = null
        last_name.value = null
        gender.value = null
        birthday.value = null
        email.value = null
        phone.value = null

        // Orders View patient
        data_table_orders.value = null

        // Reports View patient
        data_table_reports.value = null
        uploaded_apoe.value = false
        id_apoe.value = 0
        uploaded_pgx.value = false
        id_pgx.value = 0
        uploaded_genetic.value = false
        genetic_data = {}
    }
    const selectAction = () => {
        if(movement_type.value == 'add') {
            addPatient()
        }
        else if(movement_type.value == 'edit') {
            editPatient()
        }
    }

    // Methods for patient

    const listPatient = async() => {
        store.dispatch('showLoading')

        let query = {
            datatable: true,
            page: current_page.value,
            rows: items_per_page.value,
            search: '',
            order_by: 'last_name',
            order_asc: false
        }

        let response = await $sendRequest('administrator/patient/list', 'GET', null, query)
        // console.log(response, 'response listPatient')

        if(response.success && response.data) {
            data_table.value = response.data.list.result
            total_pages.value = response.data.list.total_pages
            store.dispatch('hiddenLoading')
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const addPatient = async() => {
        store.dispatch('showLoading')

        let body = {
            first_name: first_name.value,
            last_name: last_name.value,
            country: '52',
            phone: phone.value,
            email: email.value.toString().toLowerCase(),
            gender: gender.value,
            birthday: birthday.value
        }

        let response = await $sendRequest('administrator/patient/create', 'POST', body)
        // console.log(response, 'responsdashboard container-fluid bg-white p-4 addPatient')

        if(response.success && response.data.id) {
            store.dispatch('showAlert', 'Paciente agregado')
            await makeOrder(response.data.id)
            location.reload()
        }
        else if(!response.success && response.errors) {
            let message = []
            for(let error of response.errors) {
                if(error.code == 'error_empty_first_name') {
                    message.push('El campo "Nombres" es requerido')
                }
                else if(error.code == 'error_empty_last_name') {
                    message.push('El campo "Apellidos" es requerido')
                }
                else if(error.code == 'error_empty_phone') {
                    message.push('El campo "Teléfono" es requerido')
                }
                else if(error.code == 'error_empty_email') {
                    message.push('El campo "Correo" es requerido')
                }
                else if(error.code == 'error_empty_gender') {
                    message.push('El campo "Género" es requerido')
                }
                else if(error.code == 'error_empty_birthday') {
                    message.push('El campo "Fecha de Nacimiento" es requerido')
                }
                else if(error.code == 'error_already_exist_phone_patient') {
                    message.push('El teléfono ya se encuentra registrado')
                }
                else if(error.code == 'error_already_exist_email_patient') {
                    message.push('El correo ya se encuentra registrado')
                }
                else {
                    message.push('Ha ocurrido un error')
                }
            }
            store.dispatch('showError', message)
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const viewPatient = async(patient_id, patient_info) => {
        store.dispatch('showLoading')
        local_id_patient.value = patient_id
        current_info_patient = patient_info

        let query = {
            id: patient_id
        }

        let response = await $sendRequest('administrator/patient/view', 'GET', null, query)
        // console.log(response, 'response viewPatient')

        if(response.success && response.data.patient) {
            let patient = response.data.patient
                
            first_name.value = patient.first_name
            last_name.value = patient.last_name
            gender.value = patient.gender
            birthday.value = patient.birthday.substring(0, 10)
            email.value = patient.email
            phone.value = patient.phone

            //
            movement_type.value = 'edit'
            show_modal_patient.value = true

            await viewPatientReports(patient_id)
            await listOrdersPatient(patient_id)
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const editPatient = async() => {
        store.dispatch('showLoading')

        let query = {
            id: local_id_patient.value
        }
        let body = {
            first_name: first_name.value,
            last_name: last_name.value,
            country: '52',
            phone: phone.value,
            email: email.value,
            gender: gender.value,
            birthday: birthday.value
        }

        let response = await $sendRequest('administrator/patient/edit', 'PUT', body, query)

        if(response.success && response.data.update) {
            store.dispatch('showAlert', 'Paciente editado')
            store.dispatch('hiddenLoading')
            location.reload()
        }
        else if(!response.success && response.errors) {
            let message = []
            for(let error of response.errors) {
                if(error.code == 'error_empty_first_name') {
                    message.push('El campo "Nombres" es requerido')
                }
                else if(error.code == 'error_empty_last_name') {
                    message.push('El campo "Apellidos" es requerido')
                }
                else if(error.code == 'error_empty_phone') {
                    message.push('El campo "Teléfono" es requerido')
                }
                else if(error.code == 'error_empty_email') {
                    message.push('El campo "Correo" es requerido')
                }
                else if(error.code == 'error_empty_gender') {
                    message.push('El campo "Género" es requerido')
                }
                else if(error.code == 'error_empty_birthday') {
                    message.push('El campo "Fecha de Nacimiento" es requerido')
                }
                else if(error.code == 'error_already_exist_patient') {
                    message.push('El teléfono ya se encuentra registrado')
                }
                else {
                    message.push('Ha ocurrido un error')
                }
            }
            
            store.dispatch('showError', message)
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const deletePatient = async(patient_id) => {
        store.dispatch('showLoading')

        let query = {
            id: patient_id
        }

        let response = await $sendRequest('administrator/patient/inactive', 'DELETE', null, query)

        if(response.success && response.data.inactivated) {
            store.dispatch('showAlert', 'Paciente eliminado')

            setTimeout(function() {
                store.dispatch('hiddenLoading')
                location.reload()
            }, 1200);
        }
        else if(!response.success && response.errors) {
            let message = []
            for(let error of response.errors) {
                if(error.code == 'error_no_found_patient') {
                    message.push('El paciente no ha sido encontrado')
                }
                else {
                    message.push('Ha ocurrido un error')
                }
            }
            
            store.dispatch('showError', message)
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const listOrdersPatient = async(patient_id) => {
        store.dispatch('showLoading')

        let query = {
            datatable: true,
            page: 1,
            rows: 10,
            search: '',
            order_by: 'id',
            order_asc: true,
            patient_id: patient_id
        }

        let response = await $sendRequest('administrator/order/patient_orders', 'GET', null, query)
        // console.log(response, 'response listOrders')

        if(response.success && response.data.list.result) {
            data_table_orders.value = response.data.list.result
            store.dispatch('hiddenLoading')
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const viewPatientReports = async(patient_id) => {
        store.dispatch('showLoading')

        let query = {
            patient_id
        }

        let response = await $sendRequest('administrator/order/patient_reports', 'GET', null, query)
        // console.log(response, 'response viewPatient')

        if(response.success && response.data) {
            let reports = response.data.reports
            
            if(reports.apoe !== undefined && reports.apoe !== null) {
                uploaded_apoe.value = true
                id_apoe.value = reports.apoe.id
                //data_table_reports.value.push(reports.apoe)
            }
            if(reports.pgx !== undefined && reports.pgx !== null) {
                uploaded_pgx.value = true
                id_pgx.value = reports.pgx.id
                //data_table_reports.value.push(reports.pgx)
            }
            if(reports.epigenetic !== undefined && reports.epigenetic !== null && reports.epigenetic.length > 0) {
                data_table_reports.value = []
                for(let report of reports.epigenetic) {
                    data_table_reports.value.push(report)
                }
            }
            if(reports.genetic !== undefined && reports.genetic !== null) {
                uploaded_genetic.value = true
                genetic_data = reports.genetic
            }
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }


    // Methods for charge PDF

    const openChargeModal = (value, type) => {
        charge_type.value = type
        row_current = value

        if(row_current.order_id == null) {
            makeOrder(row_current.id)
        }

        show_modal_charge.value = true
    }

    const chargePDF = async() => {
        store.dispatch('showLoading')
        let id_order = (row_current.order_id !== null) ? row_current.order_id : order_id.value

        const formData = new FormData()
        formData.append("patient_id", row_current.id);
        formData.append("order_id", id_order);

        var uploadedFiles = document.getElementById('file-input');
        formData.append('file', uploadedFiles.files[0]);
        // console.log({ patient_id: row_current.id, order_id: id_order, file: uploadedFiles.files[0] })

        var xhr = new XMLHttpRequest();
        xhr.open('POST', `${CONFIG.server[CONFIG.env].api}/administrator/bulk/${charge_type.value}`);
        xhr.send(formData);
        xhr.onreadystatechange = function () {
            // console.log(xhr);
            if(xhr.readyState == 4 && xhr.status == 200) {
                let response = JSON.parse(xhr.response) || null

                if(response !== null && response.http == 200 && response.data.pdf) {
                    store.dispatch('showAlert', 'El archivo se ha cargado exitosamente')
                    
                    setTimeout(function() {
                        store.dispatch('hiddenLoading')
                        location.reload()
                    }, 1200);
                }
                else {
                    store.dispatch('showError', 'Ha ocurrido un error, el archivo no ha podido cargarse')

                    setTimeout(function() {
                        store.dispatch('hiddenLoading')
                        location.reload()
                    }, 1200);
                }
            }
            else {
                store.dispatch('showError', 'Ha ocurrido un error, el archivo no ha podido cargarse')

                setTimeout(function() {
                    store.dispatch('hiddenLoading')
                    location.reload()
                }, 1200);
            }
        }
    }

    const chargeExtraReport = async(info_report) => {
        store.dispatch('showLoading')
        let type = (info_report.id == 'drop_apoe') ? 'apoe' : (info_report.id == 'drop_pgx') ? 'pgx' : 'epigenetic'

        const formData = new FormData()
        formData.append("patient_id", local_id_patient.value);
        formData.append('file', info_report.file);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', `${CONFIG.server[CONFIG.env].api}/administrator/bulk/${type}`);
        xhr.send(formData);
        xhr.onreadystatechange = function () {
            //console.log(xhr);
            if(xhr.readyState == 4 && xhr.status == 200) {
                let response = JSON.parse(xhr.response) || null

                if(response !== null && response.http == 200 && response.data.pdf) {
                    store.dispatch('showAlert', 'El archivo se ha cargado exitosamente')

                    setTimeout(function() {
                        store.dispatch('hiddenLoading')
                        location.reload()
                    }, 1200);
                }
                else {
                    store.dispatch('showError', 'Ha ocurrido un error, el archivo no ha podido cargarse')

                    setTimeout(function() {
                        store.dispatch('hiddenLoading')
                        location.reload()
                    }, 1200);
                }
            }
            else {
                store.dispatch('showError', 'Ha ocurrido un error, el archivo no ha podido cargarse')

                setTimeout(function() {
                    store.dispatch('hiddenLoading')
                    location.reload()
                }, 1200);
            }
        }
    }

    const chargeGenomicReport = async(info_report) => {
        store.dispatch('showLoading')

        const formData = new FormData()
        formData.append("patient_id", current_info_patient.id);
        formData.append("order_id", current_info_patient.order_id);

        formData.append('file', info_report.file);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', `${CONFIG.server[CONFIG.env].api}/administrator/bulk/genetic`);
        xhr.send(formData);
        xhr.onreadystatechange = function () {
            // console.log(xhr);
            if(xhr.readyState == 4 && xhr.status == 200) {
                let response = JSON.parse(xhr.response) || null

                if(response !== null && response.http == 200 && response.data.pdf) {
                    store.dispatch('showAlert', 'El archivo se ha cargado exitosamente')

                    setTimeout(function() {
                        store.dispatch('hiddenLoading')
                        location.reload()
                    }, 1200);
                }
                else {
                    store.dispatch('showError', 'Ha ocurrido un error, el archivo no ha podido cargarse')

                    setTimeout(function() {
                        store.dispatch('hiddenLoading')
                        location.reload()
                    }, 1200);
                }
            }
            else {
                store.dispatch('showError', 'Ha ocurrido un error, el archivo no ha podido cargarse')

                setTimeout(function() {
                    store.dispatch('hiddenLoading')
                    location.reload()
                }, 1200);
            }

        }
    }


    // Methods for Orders

    const makeOrder = async(patient_id) => {
        store.dispatch('showLoading')
        let body = {
            patient_id
        }

        let response = await $sendRequest('administrator/order/make', 'POST', body)
        // console.log(response, 'response makeOrder')

        if(response.success && response.data.id) {
            order_id.value = response.data.id
            store.dispatch('hiddenLoading')
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error en la orden')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    // Methods for Download

    const downloadPDF = async(data_order, type) => {
        if((type == 'blood' && data_order.studio_blood_creation === null) ||
           (type == 'genetic' && data_order.order_id_genetic_file === null)) {
            return
        }
        let local_order_id = (type == 'genetic') ? data_order.order_id_genetic_file : data_order.order_id

        let url = `${CONFIG.server[CONFIG.env].api}/administrator/bulk/resource?patient_id=${data_order.patient_id}&order_id=${local_order_id}&type=${type}`
        window.open(url, '_blank');
    }

    const downloadGenetic = async() => {
        let url = `${CONFIG.server[CONFIG.env].api}/administrator/bulk/resource?patient_id=${genetic_data.patient_id}&order_id=${genetic_data.order_id}&type=genetic`
        window.open(url, '_blank');
    }

    const downloadReport360 = async(patient_id, order_id) => {
        let url = `${CONFIG.server[CONFIG.env].api}/administrator/bulk/g3nomic?patient_id=${patient_id}&order_id=${order_id}&pdf=${true}`
        window.open(url, '_blank');
    }

    const genereteReport360 = async(patient_id, order_id) => {
        store.dispatch('showLoading')
        let query = {
            patient_id,
            order_id,
            pdf: false
        }

        let response = await $sendRequest('administrator/bulk/g3nomic', 'GET', null, query)
        // console.log(response, 'response genereteReport360')
        
        if(response.success) {
            store.dispatch('showAlert', 'Reporte generado')
            store.dispatch('hiddenLoading')
            location.reload()
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const downloadExtraReport = async(id_type, data_report = null) => {
        // console.log(data_report, 'data_report')
        let type = (id_type == 'drop_apoe') ? 'apoe' : (id_type == 'drop_pgx') ? 'pgx' : 'epigenetic'
        let url = `${CONFIG.server[CONFIG.env].api}/administrator/bulk/resource?patient_id=${local_id_patient.value}&type=${type}`

        if(data_report !== null) {
            url = `${url}&report_id=${data_report.id}`
        }
        window.open(url, '_blank');
    }

    const deleteExtraReport = async(type_report, row) => {
        store.dispatch('showLoading')
        // console.log(row, 'row')

        let body = {
            type_report,
            report_id: (type_report == 'apoe') ? id_apoe.value : (type_report == 'pgx') ? id_pgx.value : row.id
        }

        let response = await $sendRequest('administrator/bulk/delete_report', 'DELETE', body)
        // console.log(response, 'response deleteExtraReport')
        
        if(response.success) {
            store.dispatch('hiddenLoading')
            location.reload()
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const deleteBlood = async(row) => {
        store.dispatch('showLoading')
        console.log(row, 'row')

        let body = {
            type_order: 'blood',
            order_id: row.order_id
        }

        let response = await $sendRequest('administrator/bulk/delete_order', 'DELETE', body)
        
        if(response.success) {
            store.dispatch('hiddenLoading')
            location.reload()
        }
        else if(!response.success && response.errors) {
            let message = []
            for(let error of response.errors) {
                if(error.code == 'error_order_cant_delete') {
                    message.push('Este elemento no se puede eliminar')
                }
                else {
                    message.push('Ha ocurrido un error')
                }
            }
            store.dispatch('showError', message)
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    // Others

    const handleCloseDateSample = async() => {
        show_modal_date.value = false
        date_sample_taking.value = null
        study_date.value = null
        smd_id_report.value = null
    }

    const saveDateSample = async() => {
        store.dispatch('showLoading')

        let query = {
            report_id: smd_id_report.value
        }
        let body = {
            date_sample_taking: date_sample_taking.value
        }

        let response = await $sendRequest('administrator/order/save_date_sample', 'PUT', body, query)
        
        if(response.success) {
            store.dispatch('hiddenLoading')
            location.reload()
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    // CONFIRMATION

    const handleCloseConfirmation = async() => {
        show_modal_confirmation.value = false
        title_confirmation.value = null
        action_confirmation.value = null
        data_confirmation.value = null
    }

    const showModalConfirmation = async(action, data) => {
        action_confirmation.value = action
        data_confirmation.value = data
        // console.log(data_confirmation.value)

        switch (action_confirmation.value) {
            case 'delete_patient':
                title_confirmation.value = `¿Está seguro que desea eliminar el paciente <br> ${data.first_name} ${data.last_name}?`
            break;
            case 'delete_epigenetic':
                title_confirmation.value = `¿Está seguro que desea eliminar el estudio epigenético <br>
                    con fecha de carga ${$informativeDate(data.creation)}?`
            break;
            case 'delete_apoe':
                title_confirmation.value = `¿Está seguro que desea eliminar el estudio apo e?`
            break;
            case 'delete_pgx':
                title_confirmation.value = `¿Está seguro que desea eliminar el estudio farmacogenético?`
            break;
            case 'delete_blood':
                title_confirmation.value = `¿Está seguro que desea eliminar la orden del estudio sanguíneo?`
            break;
            
            default:
                console.log(`Sorry, we are out of ${expr}.`);
        }

        show_modal_confirmation.value = true
    }

    const actionConfirmation = async() => {
        switch (action_confirmation.value) {
            case 'delete_patient':
                deletePatient(data_confirmation.value.id)
            break;
            case 'delete_epigenetic':
                deleteExtraReport('epigenetic', data_confirmation.value)
            break;
            case 'delete_apoe':
                deleteExtraReport('apoe')
            break;
            case 'delete_pgx':
                deleteExtraReport('pgx')
            break;
            case 'delete_blood':
                deleteBlood(data_confirmation.value)
            break;
            default:
                console.log(`Sorry, we are out of ${expr}.`);
        }
    }

    // CREATE

    const changeItemsPerPage = (e) => {
        // console.log(e, 'event extern')
        items_per_page.value = e.items_per_page
        current_page.value = e.page
        listPatient()
    }

    listPatient()

</script>

<style lang="scss">
    .dashboard {
        height: 100%;
        border-radius: 12px;
        overflow-y: scroll;
    }
</style>